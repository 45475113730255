import * as types from '../actions/types';

const initialState = {
  useImage: null,
  isError: false,
  isRefresh: false
};

const useImageReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.STORE_IMAGE_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.STORE_IMAGE_SUCCEDDED:
      return {
        ...state,
        useCoupon: action.data,
        error: null
      };
    case types.STORE_IMAGE_FAILED:
      return {
        ...state,
        isError: true,
        useCoupon: null,
        error: action.error
      };
    case types.DETECT_FACE_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.DETECT_FACE_SUCCEDDED:
      return {
        ...state,
        useCoupon: action.data,
        error: null
      };
    case types.DETECT_FACE_FAILED:
      return {
        ...state,
        isError: true,
        useCoupon: null,
        error: action.error
      };
    default:
      return state;
  }
};

export default useImageReducer;
