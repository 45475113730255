import React from 'react';
import './index.css';
import classnames from 'classnames';
import classNames from 'classnames';
import styles from '../../containers/PartnerDetail/index.module.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Popup extends React.PureComponent {
  handleCancel = () => {
    const { onCancelClicked } = this.props;
    if (onCancelClicked) {
      onCancelClicked();
    }
  };

  handleOkClicked = () => {
    const { onOkClicked } = this.props;
    if (onOkClicked) {
      onOkClicked();
    }
  };

  handlePositiveClicked = () => {
    const { onPositiveClicked } = this.props;
    if (onPositiveClicked) {
      onPositiveClicked();
    }
  };

  handleOtherPositiveClicked = () => {
    const { onOtherPositiveClicked } = this.props;
    if (onOtherPositiveClicked) {
      onOtherPositiveClicked();
    }
  };

  handleNegativeClicked = () => {
    const { onNegativeClicked } = this.props;
    if (onNegativeClicked) {
      onNegativeClicked();
    }
  };

  handleNeutralClicked = () => {
    const { onNeutralClicked } = this.props;
    if (onNeutralClicked) {
      onNeutralClicked();
    }
  };

  renderTitle() {
    const { title, titleClassName, TitleComponent } = this.props;
    if (TitleComponent) {
      return TitleComponent;
    }
    if (title) {
      return (
        <div className="title-container">
          <div className={titleClassName || 'title title--pre-line'}>
            {title}
          </div>
        </div>
      );
    }
    return null;
  }

  renderFooter() {
    const { footer, footerClassName, FooterComponent } = this.props;
    if (FooterComponent) {
      return FooterComponent;
    }
    if (footer) {
      return (
        <div className="footer-container">
          <div className={footerClassName || 'footer'}>{footer}</div>
        </div>
      );
    }
    return null;
  }

  renderMessages() {
    const {
      messages,
      MessagesComponent,
      messageStyle,
      messageClassName,
      messagesContainerClassName = 'message-container',
      messageWithBreak,
      contentTextLeft = false,
      detailInfoLink = '',
      detailInfoText = '',
      textLeftMsg = false,
      listItemComponent,
      messagesContainerAddClass = '',
      boldMsgIndex = []
    } = this.props;
    if (MessagesComponent) {
      return MessagesComponent;
    }
    if (listItemComponent) {
      return listItemComponent;
    }
    if (messages && messages.length > 0) {
      var messageItemClass = 'message';
      messageItemClass +=
        messages.length === 1 ? ' message-one-line' : ' message-multiple-lines';

      if (textLeftMsg) {
        messageItemClass += ' text-left';
      }

      if (messageClassName) {
        messageItemClass = messageClassName;
      }

      return (
        <div
          className={classNames(
            messagesContainerClassName,
            messagesContainerAddClass
          )}
        >
          {messages.map((element, i) => {
            if (element) {
              // Add line-break for not last message
              if (messageWithBreak && messages.length !== i + 1) {
                return (
                  <div
                    key={i}
                    className={messageItemClass}
                    // className={messageItemClass + ' message-line-break'}
                    style={messageStyle}
                  >
                    {this.renderElement(element)}
                  </div>
                );
              }

              return (
                <div
                  key={i}
                  className={`${messageItemClass}
                    ${contentTextLeft ? 'content-text-left' : ''}
                    ${boldMsgIndex.includes(i) ? 'font-weight-bold' : ''}`}
                  style={messageStyle}
                >
                  {typeof element === 'string'
                    ? this.renderElement(element)
                    : element}
                </div>
              );
            }
            return null;
          })}
          {detailInfoLink && detailInfoText && (
            <div className={styles.detailInfoLinkGroup}>
              <div
                className={classNames(styles.textLink)}
                onClick={() => {
                  window.open(detailInfoLink);
                }}
              >
                {detailInfoText}
              </div>
            </div>
          )}
        </div>
      );
    }
    return null;
  }

  renderElement = element => {
    switch (true) {
      case element.includes('水曜: 18時半ごろ'):
        return (
          <div className={'message-row-line'}>
            <div className={'message-bold'}>水曜:</div>
            <div style={{ marginLeft: 8 }}>18時半ごろ</div>
          </div>
        );
      case element.includes('土/日曜: 17時ごろ'):
        return (
          <div className={'message-row-line'}>
            <div className={'message-bold'}>土/日曜:</div>
            <div style={{ marginLeft: 8 }}>17時ごろ</div>
          </div>
        );
      case element.includes('予約変更、取消しはデート'):
        return (
          <div
            className="message-row-line-margin"
            dangerouslySetInnerHTML={{
              __html:
                '<p>予約変更、取消しは<span style="color: red">デート前日12時</span>まで可能です。</p>'
            }}
          />
        );
      default:
        return element;
    }
  };

  renderOkButton(isWhiteMode) {
    const buttonLabel = this.props.okButtonTitle;
    const buttonClass = this.props.okButtonClass;
    if (buttonLabel) {
      return (
        <div
          className={classNames({
            ['button button-ok ' + (buttonClass ? buttonClass : '')]: true,
            'text-white': isWhiteMode
          })}
          onClick={this.handleOkClicked}
        >
          {buttonLabel}
        </div>
      );
    }
    return null;
  }

  renderPositiveButton(isWhiteMode) {
    const buttonLabel = this.props.positiveButtonTitle;
    const { positiveButtonClassName } = this.props;
    const className = positiveButtonClassName || 'button button-positive';
    if (buttonLabel) {
      return (
        <div
          className={classNames({
            [className]: true,
            'text-white': isWhiteMode
          })}
          onClick={this.handlePositiveClicked}
        >
          {buttonLabel}
        </div>
      );
    }
    return null;
  }

  renderOtherPositiveButton(isWhiteMode) {
    const buttonLabel = this.props.positiveOtherButtonTitle;
    const { positiveButtonClassName } = this.props;
    const className = positiveButtonClassName || 'button button-positive';
    if (buttonLabel) {
      return (
        <div
          className={classNames({
            [className]: true,
            'text-white': isWhiteMode
          })}
          onClick={this.handleOtherPositiveClicked}
        >
          {buttonLabel}
        </div>
      );
    }
    return null;
  }

  renderNegativeButton(isWhiteMode) {
    const buttonLabel = this.props.negativeButtonTitle;
    const { negativeButtonClassName } = this.props;
    const className = negativeButtonClassName || 'button button-negative';
    if (buttonLabel) {
      return (
        <div
          className={classNames({
            [className]: true,
            'text-white': isWhiteMode
          })}
          onClick={this.handleNegativeClicked}
        >
          {buttonLabel}
        </div>
      );
    }
    return null;
  }

  renderNeutralButton(isWhiteMode) {
    const buttonLabel = this.props.neutralButtonTitle;
    if (buttonLabel) {
      let buttonClass = this.props.neutralButtonClass || '';
      if (
        this.props.selectedElements &&
        this.props.selectedRequiredCount &&
        this.props.selectedElements.length < this.props.selectedRequiredCount
      ) {
        buttonClass += ' ' + styles.disableBtn;
      }
      return (
        <div
          className={classNames({
            ['button button-neutral' +
            (buttonClass ? ' ' + buttonClass : '')]: true,
            'text-white': isWhiteMode
          })}
          onClick={this.handleNeutralClicked}
        >
          <div>{buttonLabel}</div>
        </div>
      );
    }
    return null;
  }

  renderButtons(isWhiteMode) {
    const { ButtonComponent, buttonContainerStyle = '' } = this.props;
    if (ButtonComponent) {
      return ButtonComponent;
    }
    return (
      <div className={classNames('button-container', buttonContainerStyle)}>
        {this.renderOtherPositiveButton(isWhiteMode)}
        {this.renderPositiveButton(isWhiteMode)}
        {this.renderNegativeButton(isWhiteMode)}
        {this.renderNeutralButton(isWhiteMode)}
        {this.renderOkButton(isWhiteMode)}
        {this.renderFooter()}
        {this.props.renderBottomButton && this.props.renderBottomButton()}
      </div>
    );
  }

  renderCloseButton() {
    const { disableClose, customCloseButton } = this.props;
    if (disableClose) {
      return null;
    }
    return customCloseButton ? (
      customCloseButton
    ) : (
      <div className="close-button-container" onClick={this.handleCancel}>
        <div
          className={classNames({
            'close-button': true
          })}
        />
      </div>
    );
  }

  render() {
    if (!this.props.visible) {
      document.body.classList.remove('popup-open');
      return null;
    }
    document.body.classList.add('popup-open');

    const {
      headerClassName = '',
      footerClassName = 'footer',
      contentClassName = null,
      contentStyle = 'content',
      hyperLink,
      hyperLinkCustomText = '',
      hyperLinkCustomClass = '',
      hyperLinkCustomClick = () => {},
      customPopup = '',
      detailInfoLink = '',
      detailInfoText = '',
      whiteModePopup,
      isIOS,
      imageUnderTitle = '',
      imageContentStyle = 'image-content',
      isWhiteMode,
      popupContent = '',
      bodyStyle = '',
      customContentStyle = '',
      customFooterStyle = '',
      customPopUpWidth = '100%'
    } = this.props;

    return (
      <>
        <div className={`popup ${customPopup}`}>
          <div className="popup-dialog popup-centered">
            <div
              className={classNames(
                'popup-content',
                whiteModePopup && 'popup-content-white-mode',
                popupContent
              )}
              style={{ width: customPopUpWidth }}
            >
              <div className={classNames('popup-body', bodyStyle)}>
                <div className={`header ${headerClassName}`}>
                  {this.renderTitle()}
                </div>
                {imageUnderTitle && (
                  <div className={imageContentStyle}>
                    <img
                      alt="Under title img"
                      src={imageUnderTitle}
                      className={'w-100'}
                    />
                  </div>
                )}
                {this.renderMessages() ? (
                  <div
                    className={classnames(
                      contentClassName,
                      contentStyle,
                      detailInfoLink && detailInfoText
                        ? 'content-has-bottom-link'
                        : '',
                      customContentStyle
                    )}
                  >
                    {this.renderMessages()}
                  </div>
                ) : null}
                {hyperLink ? (
                  hyperLinkCustomText ? (
                    <div
                      className={classNames(
                        'hyper-link custom-hyper-link hyper-link-bold',
                        {
                          [hyperLinkCustomClass]: !!hyperLinkCustomClass
                        }
                      )}
                      onClick={hyperLinkCustomClick}
                    >
                      {hyperLinkCustomText}
                    </div>
                  ) : (
                    <div className="hyper-link">
                      マッチングの詳しい仕組みはこちら＞
                    </div>
                  )
                ) : null}
                <div
                  className={classNames({
                    [styles.iosBlur]: isIOS,
                    [styles.iosBlurWhiteMode]: isIOS && whiteModePopup
                  })}
                ></div>
                {!this.props.notShowButton && (
                  <div
                    className={classNames(footerClassName, customFooterStyle)}
                  >
                    {this.props.btnErrorMsg &&
                      this.props.selectedElements &&
                      this.props.selectedRequiredCount &&
                      this.props.selectedElements.length <
                        this.props.selectedRequiredCount && (
                        <div
                          className={classNames(
                            styles.notice,
                            styles.buttonError
                          )}
                        >
                          {this.props.btnErrorMsg}
                        </div>
                      )}
                    {!this.props.notShowButton &&
                      this.renderButtons(isWhiteMode)}
                  </div>
                )}
                {!this.props.notShowCloseButton
                  ? this.renderCloseButton(isWhiteMode)
                  : null}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show" />
      </>
    );
  }
}

Popup.propTypes = {
  onCancelClicked: PropTypes.func,
  onOkClicked: PropTypes.func,
  onPositiveClicked: PropTypes.func,
  onOtherPositiveClicked: PropTypes.func,
  onNegativeClicked: PropTypes.func,
  onNeutralClicked: PropTypes.func,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  TitleComponent: PropTypes.element,
  footer: PropTypes.string,
  footerClassName: PropTypes.string,
  FooterComponent: PropTypes.element,
  messages: PropTypes.array,
  MessagesComponent: PropTypes.element,
  messageStyle: PropTypes.any,
  messageClassName: PropTypes.string,
  messagesContainerClassName: PropTypes.string,
  messageWithBreak: PropTypes.bool,
  contentTextLeft: PropTypes.bool,
  detailInfoLink: PropTypes.string,
  detailInfoText: PropTypes.string,
  textLeftMsg: PropTypes.bool,
  listItemComponent: PropTypes.element,
  messagesContainerAddClass: PropTypes.string,
  boldMsgIndex: PropTypes.array,
  okButtonTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  okButtonClass: PropTypes.string,
  positiveButtonTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  positiveButtonClassName: PropTypes.string,
  positiveOtherButtonTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  negativeButtonTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  negativeButtonClassName: PropTypes.string,
  neutralButtonTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  neutralButtonClass: PropTypes.string,
  selectedElements: PropTypes.array,
  selectedRequiredCount: PropTypes.number,
  ButtonComponent: PropTypes.element,
  renderBottomButton: PropTypes.bool,
  disableClose: PropTypes.bool,
  customCloseButton: PropTypes.element,
  visible: PropTypes.bool,
  headerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  contentStyle: PropTypes.string,
  hyperLink: PropTypes.bool,
  hyperLinkCustomText: PropTypes.string,
  hyperLinkCustomClass: PropTypes.string,
  hyperLinkCustomClick: PropTypes.func,
  customPopup: PropTypes.string,
  whiteModePopup: PropTypes.bool,
  isIOS: PropTypes.bool,
  imageUnderTitle: PropTypes.string,
  imageContentStyle: PropTypes.string,
  customPopUpWidth: PropTypes.string,
};

Popup.defaultProps = {};

const mapStateToProps = state => {
  return {
    isWhiteMode: state.app.isWhiteMode
  };
};

export default connect(mapStateToProps, {})(Popup);
