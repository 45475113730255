import React from 'react';
import { pathOr } from 'ramda';
import Popup from '@components/Popup';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';
import { logoutFacebook } from '@redux/actions';
import { NOT_SHOW_ERROR_POPUP_KEY, ResponseStatus } from '@constants/constant';
import TitleCustom from '../../components/Popup/TitleCustom';
import MessageCustom from '../../components/Popup/MessageCustom';
import { withRouter } from 'react-router-dom';

class ErrorPopup extends React.PureComponent {
  handleCancel = () => {
    const { hideError } = this.props;
    if (hideError) {
      hideError();
    }
  };

  handleCancelGlobal = () => {
    const { resetErrorPopup, globalNextUrl } = this.props;
    if (resetErrorPopup) {
      resetErrorPopup();
    }
    if (globalNextUrl) {
      window.location.replace(globalNextUrl);
    }
  };

  handleResetError = () => {
    const { hideError, resetErrorPopup } = this.props;
    hideError();
    resetErrorPopup();
  };

  handleLogout = () => {
    this.handleResetError();
    this.props.history.push('/login');
  };

  handleResumeSubscriptionPositiveClick = () => {
    const { resetErrorPopup } = this.props;
    resetErrorPopup();
    window.location.replace('/cancel-subscription-pause-request');
  };

  handleResumeSubscriptionNegativeClick = () => {
    const { resetErrorPopup, logoutFacebook, hideError } = this.props;
    hideError();
    resetErrorPopup();
    logoutFacebook({}, response => {
      if (response.status === ResponseStatus.SUCCESS) {
        this.props.history.push('/login');
      }
    });
  };

  render() {
    const {
      message,
      error,
      buttonTitle,
      globalErrorType,
      globalErrorMessage,
      globalErrorTitle,
      globalNextUrl,
      isSubscriptionPause,
      logoutFacebook
    } = this.props;
    const t = i18n.getFixedT(null, null, 'popups');

    if (globalErrorTitle && !NOT_SHOW_ERROR_POPUP_KEY.includes(globalErrorType)) {
      if (globalErrorType === 'expired_subscription_pause_cancel_period') {
        const isNotSmsVerificationCodePage =
          window.location.pathname !== '/sms-verification-code';
        const handleOkClicked = () => {
          this.handleResetError();
          if (isNotSmsVerificationCodePage) {
            logoutFacebook({}, response => {
              if (response.status === ResponseStatus.SUCCESS) {
                this.props.history.push('/login');
              }
            });
          } else {
            this.props.history.push('/login');
          }
        };

        return (
          <Popup
            visible={true}
            title={globalErrorTitle}
            messages={globalErrorMessage}
            messageClassName={'align-left'}
            notShowCloseButton={true}
            okButtonTitle={
              isNotSmsVerificationCodePage ? 'ログアウト' : '閉じる'
            }
            onOkClicked={handleOkClicked}
          />
        );
      }
      let buttonTitle = isSubscriptionPause ? 'ログアウト' :
          [
            'cancel_deadline_is_passed',
            'can_not_book_date'
          ].includes(globalErrorType) ? '了解' : '';
      if (globalNextUrl) {
        buttonTitle = 'お支払い確認ページへ';
      }

      if (globalErrorType === 'active_subscription_pause') {
        return (
          <Popup
            visible={true}
            title={globalErrorTitle}
            messages={globalErrorMessage}
            messageClassName={'align-left'}
            notShowCloseButton={true}
            positiveButtonTitle={'キャンセル手続きに進む'}
            onPositiveClicked={this.handleResumeSubscriptionPositiveClick}
            negativeButtonTitle={'やめる'}
            onNegativeClicked={this.handleResumeSubscriptionNegativeClick}
          />
        );
      }

      return (
        <Popup
          visible={true}
          title={globalErrorTitle}
          messages={globalErrorMessage}
          okButtonTitle={buttonTitle}
          onCancelClicked={this.handleResetError}
          onOkClicked={this.handleCancelGlobal}
          disableClose={!!isSubscriptionPause}
          messageClassName={'globalErrorMessages'}
          titleClassName={'globalErrorTitle'}
        />
      );
    }

    const errorCode = pathOr(-1, ['response', 'status'], error);

    // Don't show popup unauthorzied
    if (errorCode === 401) {
      return null;
    }

    if (!message) {
      return null;
    }

    if (buttonTitle) {
      return (
        <Popup
          visible={true}
          TitleComponent={
            <TitleCustom
              message1={t('popups:continue-subscription-pause-title')}
            />
          }
          MessagesComponent={
            <MessageCustom
              messages={[
                {
                  content: message,
                  icon: null
                }
              ]}
            />
          }
          okButtonTitle={buttonTitle}
          onOkClicked={this.handleLogout}
          disableClose={true}
        />
      );
    }

    return (
      <Popup
        visible={true}
        title={message}
        onCancelClicked={this.handleCancel}
        notShowButton
      />
    );
  }
}

ErrorPopup.propTypes = {};

ErrorPopup.defaultProps = {};

const mapStateToProps = state => {
  let errorMessage = '';
  let buttonTitle = '';
  const globalErrorType = state.globalErrorPopup.type || '';
  const globalErrorMessage = state.globalErrorPopup.message || [];
  const globalErrorTitle = state.globalErrorPopup.title || '';
  const globalNextUrl = state.globalErrorPopup.nextUrl || undefined;
  const isSubscriptionPause =
    state.globalErrorPopup.isSubscriptionPause || false;
  const t = i18n.getFixedT(null, null, 'popups');
  buttonTitle = globalErrorTitle && globalNextUrl ? 'お支払い確認ページへ' : '';

  const authError = pathOr('', ['auth', 'error'], state);

  errorMessage = state.auth.isError &&
    globalErrorType !== 'expired_subscription_pause_cancel_period'
    ? authError.status === ResponseStatus.INVALID_VERIFICATION_CODE
      ? ''
      : authError.message || t('popups:error-message')
    : '';

  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.auth.error
    };
  }

  errorMessage = state.homescreen.isError
    ? pathOr('', ['homescreen', 'error', 'message'], state)
    : '';
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.homescreen.error
    };
  }

  errorMessage = state.match.isError
    ? pathOr('', ['match', 'error', 'message'], state)
    : '';
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.match.error
    };
  }

  errorMessage = state.popups.isError
    ? pathOr('', ['popups', 'error', 'message'], state)
    : '';
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.popups.error
    };
  }

  errorMessage = state.userProfile.isError
    ? pathOr('', ['userProfile', 'error', 'message'], state)
    : '';
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.userProfile.error
    };
  }

  errorMessage = state.chatRoom.isError
    ? pathOr('', ['chatRoom', 'error', 'message'], state)
    : '';
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.chatRoom.error
    };
  }

  errorMessage = state.cafe.isError
    ? pathOr('', ['cafe', 'error', 'message'], state)
    : '';
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.cafe.error
    };
  }

  errorMessage = state.chatMessages.isError
    ? pathOr('', ['chatMessages', 'error', 'message'], state)
    : '';
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.chatMessages.error
    };
  }

  // Show popup error for coupons
  errorMessage = state.coupons.isError
    ? pathOr('', ['coupons', 'error', 'message'], state)
    : '';

  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.coupons.error
    };
  }

  errorMessage = state.subscriptionPaused.isError
    ? pathOr('', ['subscriptionPaused', 'error', 'message'], state) ||
      t('popups:error-message')
    : '';
  if (errorMessage === 'CONTINUE_SUBSCRIPTION_PAUSED') {
    errorMessage = t('popups:continue-subscription-pause-message');
    buttonTitle = t('popups:continue-subscription-pause-button');
    return {
      message: errorMessage,
      error: state.subscriptionPaused.error,
      buttonTitle: buttonTitle
    };
  }
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.subscriptionPaused.error
    };
  }

  return {
    message: '',
    error: null,
    buttonTitle,
    globalErrorType,
    globalErrorMessage,
    globalErrorTitle,
    globalNextUrl,
    isSubscriptionPause
  };
};

export default compose(
  withNamespaces('popups', { wait: true }),
  connect(mapStateToProps, {
    logoutFacebook
  }),
  withRouter
)(ErrorPopup);
